import React from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    useDisclosure,
    VStack,
    HStack,
    Image,
} from '@chakra-ui/react';
import { BiMenuAltLeft } from "react-icons/bi"
import { Link } from 'react-router-dom';
import img1 from '../assets/googleplay.png';

function Header() {
    const {isOpen, onOpen, onClose} = useDisclosure();
    return (
        <>
            <Button zIndex={'overlay'} pos={'fixed'} top={"2"} left={"2"} colorScheme='purple' p={"0"} w={"10"} h={"10"} borderRadius={"full"} onClick={onOpen}>
                <BiMenuAltLeft size={"20"} />
            </Button>

            <Drawer isOpen={isOpen} placement='left' onClose={onClose}>
                <DrawerOverlay/>
                <DrawerContent>
                    <DrawerCloseButton/>
                    <DrawerHeader>Rx Pharma</DrawerHeader>
                    <DrawerBody>
                        <VStack alignItems={"flex-start"}>
                            <Button onClick={onClose} variant={'ghost'} colorScheme='purple'>
                                <Link to={"/"}>Home</Link>
                            </Button>
                            <Button onClick={onClose} variant={'ghost'} colorScheme='purple'>
                                <Link to={"/studynotes"}>Study Notes</Link>
                            </Button>
                            <Button onClick={onClose} variant={'ghost'} colorScheme='purple'>
                                <Link to={"/casestudy"}>Case Study</Link>
                            </Button>
                            <Button onClick={onClose} variant={'ghost'} colorScheme='purple'>
                                <Link to={"/videos"}>Videos</Link>
                            </Button>
                            <Button onClick={onClose} variant={'ghost'} colorScheme='purple'>
                                <Link to={"/profile"}>Profile</Link>
                            </Button>
                            <Button onClick={onClose} variant={'ghost'} colorScheme='purple'>
                                <Link to={"/TermsAndConditions"}>Terms & Conditions</Link>
                            </Button>
                            <Button onClick={onClose} variant={'ghost'} colorScheme='purple'>
                                <Link to={"/PrivacyPolicyPage"}>Privacy Policy</Link>
                            </Button>
                            <Button onClick={onClose} variant={'ghost'} colorScheme='purple'>
                                <Link to={"/PricingPage"}>Pricing</Link>
                            </Button>
                            <Button onClick={onClose} variant={'ghost'} colorScheme='purple'>
                                <Link to={"/CancellationRefundPolicy"}>Refund Policy</Link>
                            </Button>
                            <Button onClick={onClose} variant={'ghost'} colorScheme='purple'>
                                <Link to={"/ContactUsPage"}>Contact Us</Link>
                            </Button>
                            <Button onClick={onClose} variant={'ghost'} colorScheme='purple'>
                                <Link to={"/AboutUsPage"}>About Us</Link>
                            </Button>
                            <Button onClick={onClose} variant={'ghost'} colorScheme='purple'>
                                <Link to={"/DonateUsPage"}>Donate Us</Link>
                            </Button>
                        </VStack>

                        <HStack 
                            position={'absolute'}
                            bottom={'10'}
                            left={'0'}
                            width={'full'}
                            justifyContent={'space-evenly'}
                          >
                            <Button onClick={onClose} colorScheme='purple'>
                                <Link to={"/login"}>Log In</Link>
                            </Button>

                            <Button onClick={onClose} variant={'ghost'} colorScheme='purple'>
                                <a target='blank' href='https://play.google.com/store/apps/details?id=com.nstechno.pfdekho'><Image height={'16'} width={'full'} fit={'contain'} src={img1}/></a>
                            </Button>
                        </HStack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default Header
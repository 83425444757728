import React from 'react';

const PricingPage = () => {
  return (
    <div style={{ background: '#0a1d38', fontFamily: 'Lato, sans-serif', fontWeight: 400, padding: '24px' }}>
      <div style={{ background: '#ffffff', borderRadius: '32px 32px 0 0', padding: '32px 24px' }}>
        <div style={{ margin: '0 auto', maxWidth: '1000px' }}>
          <p style={{ fontSize: '24px', lineHeight: '30px', color: '#213554', margin: 0, fontWeight: 700 }}>Pricing</p>
          <div style={{ width: '28px', height: '5px', backgroundColor: '#213554', marginTop: '16px' }}></div>
          <p style={{ margin: '16px 0 0', color: '#213554ab', fontWeight: 700 }}>Effective as of Feb 20th 2025</p>

          <p style={{ color: '#515978', margin: '16px 0 0', fontSize: '14px', lineHeight: '20px' }}>
            Our pricing model is simple and transparent. You can view all content for free. However, if you wish to download any content, a fee of ₹10 will be charged per download.
          </p>

          <p style={{ color: '#515978', margin: '16px 0 0', fontSize: '14px', lineHeight: '20px', fontWeight: 'bold' }}>Key Points:</p>
          <ul style={{ paddingLeft: '20px', color: '#515978', fontSize: '14px', lineHeight: '20px' }}>
            <li>Viewing content is free of charge.</li>
            <li>Downloading any content will cost ₹10 per download.</li>
            <li>Payment is processed securely through our payment gateway.</li>
          </ul>

          <p style={{ color: '#515978', margin: '16px 0 0', fontSize: '14px', lineHeight: '20px' }}>
            We appreciate your support and aim to provide the best experience for all our users.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
import React from 'react';

const PaymentFailedPage = () => {
  return (
    <div style={{ textAlign: 'center', padding: '40px 0', background: '#EBF0F5' }}>
      <div
        style={{
          background: 'white',
          padding: '60px',
          borderRadius: '4px',
          boxShadow: '0 2px 3px #C8D0D8',
          display: 'inline-block',
          margin: '0 auto',
        }}
      >
        <div
          style={{
            borderRadius: '200px',
            height: '200px',
            width: '200px',
            background: '#F8FAF5',
            margin: '0 auto',
          }}
        >
          <i
            style={{
              color: '#D9534F',
              fontSize: '100px',
              lineHeight: '200px',
              marginLeft: '-15px',
            }}
          >
            ✘
          </i>
        </div>
        <h1
          style={{
            color: '#D9534F',
            fontFamily: "'Nunito Sans', 'Helvetica Neue', sans-serif",
            fontWeight: '900',
            fontSize: '40px',
            marginBottom: '10px',
          }}
        >
          Payment Failed
        </h1>
        <p
          style={{
            color: '#404F5E',
            fontFamily: "'Nunito Sans', 'Helvetica Neue', sans-serif",
            fontSize: '20px',
            margin: '0',
          }}
        >
          Unfortunately, there was an issue with your payment.<br /> Please try again later.
        </p>
      </div>
    </div>
  );
};

export default PaymentFailedPage;

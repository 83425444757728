import { useNavigate } from "react-router-dom";
import { createRazorpayOrder } from "../services/apiservices";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";

const DonateUsPage = () => {
  const navigate = useNavigate();
  let { loading } = useSelector(state => state.content);
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const amount = e.target.amount.value;

    // Razorpay options (you can change it based on your integration)
    dispatch({
      type: "setLoader",
      payload: true
    });
    const order = await createRazorpayOrder(amount);
    dispatch({
      type: "setLoader",
      payload: false
    });
    if (order.error) {
      console.log('Payment Failed:', order.error);
      // Redirect to failure page on payment failure
      navigate('/PaymentFailed');
    } else {
      const options = {
        key: order.keyId, // Your Razorpay key here
        amount: amount * 100, // Amount in paise
        currency: 'INR',
        order_id: order.orderId,
        name: 'RxPharma',
        description: 'Payment',
        theme: {
          color: '#F37254',
        },
        handler: (response) => {
          console.log('Payment Success:', response);
          // Redirect to success page on successful payment
          navigate('/PaymentSuccess');
        },
        onPaymentFailure: (response) => {
          console.log('Payment Failed:', response);
          // Redirect to failure page on payment failure
          navigate('/PaymentFailed');
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    }
  };

  return (
    <div style={{ textAlign: 'center', padding: '30px 0', background: '#EBF0F5' }}>
      <div
        style={{
          background: 'white',
          padding: '60px',
          borderRadius: '4px',
          boxShadow: '0 2px 3px #C8D0D8',
          display: 'inline-block',
          margin: '0 auto',
        }}
      >
        <div
          style={{
            borderRadius: '150px',
            height: '150px',
            width: '150px',
            background: '#F8FAF5',
            margin: '0 auto',
          }}
        >
          <i
            style={{
              color: '#F37254',
              fontSize: '100px',
              lineHeight: '150px',
              marginLeft: '-15px',
            }}
          >
            💸
          </i>
        </div>
        <h1
          style={{
            color: '#88B04B',
            fontFamily: "'Nunito Sans', 'Helvetica Neue', sans-serif",
            fontWeight: '900',
            fontSize: '30px',
            marginBottom: '10px',
          }}
        >
          Payment Page
        </h1>
        <p
          style={{
            color: '#404F5E',
            fontFamily: "'Nunito Sans', 'Helvetica Neue', sans-serif",
            fontSize: '15px',
            margin: '0',
          }}
        >
          Enter the amount and click submit to proceed with the payment.
        </p>
        {loading
          ? <Loader />
          : <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '10px' }}>
            <input
              style={{
                padding: '10px',
                borderRadius: '10px',
                border: '1px solid #213554',
                marginTop: '8px',
                textAlign: 'center',
              }}
              type="number"
              id="amount"
              placeholder="Enter amount"
              required
            />
            <button
              style={{
                padding: '10px',
                borderRadius: '10px',
                border: '1px solid #213554',
                backgroundColor: '#F37254',
                color: 'white',
                marginTop: '20px',
                cursor: 'pointer',
              }}
              type="submit"
            >
              Submit Payment
            </button>
          </form>
        }
      </div>
    </div>
  );
};

export default DonateUsPage;
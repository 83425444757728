import { Box, Container, Heading, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import img from '../assets/pdf.png'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import Loader from './Loader';
function CaseStudy() {
    const navigate = useNavigate();
    let { caseStudyList } = useSelector(state => state.content);
    let { loading } = useSelector(state => state.content);

    const onPdfClick = (url) => {
        console.log(url)
        navigate("/pdfviewer", {
            state: { pdfUrl: url },
        });
    }
    return (
        <>
            <VStack>
                <Heading
                    textTransform={'uppercase'}
                    width={'fit-content'}
                    paddingY={'2'}
                    borderBottom={'2px solid'}
                    margin={"auto"}
                >
                    Case Study
                </Heading>
                <Container maxW={"container.xl"}>
                    {loading || caseStudyList.length === 0 ? <Loader /> :
                        <Box display="flex" flexWrap="wrap" justifyContent="center">

                            {caseStudyList.map((item, index) => (
                                <Box key={index} m={4} textAlign="center" width={"350px"}>

                                    <VStack>
                                        <VStack onClick={() => onPdfClick(item.url)}>
                                            <Image src={img} alt={item.name} boxSize="250px" width={"250px"} />
                                            <Text fontSize="lg" fontWeight="bold" noOfLines={2}>
                                                {item.name}
                                            </Text>
                                        </VStack>
                                        {/* <HStack
                                            width={'full'}
                                            justifyContent={'space-evenly'}
                                        >
                                            <Button colorScheme='purple' width={'full'}>
                                                Update
                                            </Button>
                                            <Button colorScheme='red' width={'full'}>
                                                Delete
                                            </Button>
                                        </HStack> */}
                                    </VStack>

                                </Box>
                            ))}

                        </Box>
                    }
                </Container>
            </VStack>
        </>
    )
}

export default CaseStudy
import React from 'react';

const TermsAndConditions = () => {
  return (
    <div style={{ background: '#0a1d38', fontFamily: 'Lato, sans-serif', fontWeight: 400 }}>
      <div style={{ background: '#ffffff', borderRadius: '32px 32px 0 0', padding: '32px 24px' }}>
        <div style={{ margin: '0 auto', maxWidth: '1000px' }}>
          <p style={{ fontSize: '24px', lineHeight: '30px', color: '#213554', fontWeight: 700, margin: 0 }}>
            Terms & Conditions
          </p>
          <div style={{ width: '28px', height: '5px', backgroundColor: '#213554', marginTop: '16px' }}></div>
          <p style={{ margin: '16px 0 0', color: '#213554ab', fontWeight: 700 }}>Last updated on Feb 20th 2025</p>
          <p style={{ color: '#515978', margin: '16px 0 0', fontSize: '14px', lineHeight: '20px' }}>
            For the purpose of these Terms and Conditions, The term "we", "us", "our" used anywhere on this page shall mean
            Rx Pharma, whose registered/operational office is Rk university, Rajkot Rajkot GUJARAT 360020. "you", “your”, "user", “visitor” shall mean any natural or legal person who is visiting our website and/or agreed to purchase from us.
          </p>
          <p style={{ color: '#515978', margin: '16px 0 0', fontSize: '14px', lineHeight: '20px' }}>
            <strong>Your use of the website and/or purchase from us are governed by following Terms and Conditions:</strong>
          </p>
          <ul style={{ margin: 0, paddingLeft: '16px' }}>
            <li style={{ paddingLeft: '5px' }}>
              <p style={{ marginTop: '8px', color: '#515978', fontSize: '14px', lineHeight: '20px' }}>
                The content of the pages of this website is subject to change without notice.
              </p>
            </li>
            <li style={{ paddingLeft: '5px' }}>
              <p style={{ marginTop: '8px', color: '#515978', fontSize: '14px', lineHeight: '20px' }}>
                Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose.
              </p>
            </li>
            <li style={{ paddingLeft: '5px' }}>
              <p style={{ marginTop: '8px', color: '#515978', fontSize: '14px', lineHeight: '20px' }}>
                Your use of any information or materials on our website and/or product pages is entirely at your own risk, for which we shall not be liable.
              </p>
            </li>
            <li style={{ paddingLeft: '5px' }}>
              <p style={{ marginTop: '8px', color: '#515978', fontSize: '14px', lineHeight: '20px' }}>
                Our website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics.
              </p>
            </li>
            <li style={{ paddingLeft: '5px' }}>
              <p style={{ marginTop: '8px', color: '#515978', fontSize: '14px', lineHeight: '20px' }}>
                All trademarks reproduced in our website which are not the property of, or licensed to, the operator are acknowledged on the website.
              </p>
            </li>
            <li style={{ paddingLeft: '5px' }}>
              <p style={{ marginTop: '8px', color: '#515978', fontSize: '14px', lineHeight: '20px' }}>
                Unauthorized use of information provided by us shall give rise to a claim for damages and/or be a criminal offense.
              </p>
            </li>
            <li style={{ paddingLeft: '5px' }}>
              <p style={{ marginTop: '8px', color: '#515978', fontSize: '14px', lineHeight: '20px' }}>
                From time to time our website may also include links to other websites. These links are provided for your convenience to provide further information.
              </p>
            </li>
            <li style={{ paddingLeft: '5px' }}>
              <p style={{ marginTop: '8px', color: '#515978', fontSize: '14px', lineHeight: '20px' }}>
                You may not create a link to our website from another website or document without Rx Pharma’s prior written consent.
              </p>
            </li>
            <li style={{ paddingLeft: '5px' }}>
              <p style={{ marginTop: '8px', color: '#515978', fontSize: '14px', lineHeight: '20px' }}>
                Any dispute arising out of use of our website and/or purchase with us is subject to the laws of India.
              </p>
            </li>
            <li style={{ paddingLeft: '5px' }}>
              <p style={{ marginTop: '8px', color: '#515978', fontSize: '14px', lineHeight: '20px' }}>
                We shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;

import React from 'react';

const ContactUsPage = () => {
  return (
    <div style={{ background: '#0a1d38', fontFamily: 'Lato, sans-serif', fontWeight: 400, padding: '24px' }}>
      <div style={{ background: '#ffffff', borderRadius: '32px 32px 0 0', padding: '32px 24px' }}>
        <div style={{ margin: '0 auto', maxWidth: '1000px' }}>
          <p style={{ fontSize: '24px', lineHeight: '30px', color: '#213554', margin: 0, fontWeight: 700 }}>Contact us</p>
          <div style={{ width: '28px', height: '5px', backgroundColor: '#213554', marginTop: '16px' }}></div>
          <p style={{ margin: '16px 0 0', color: '#213554ab', fontWeight: 700 }}>Last updated on Feb 20th 2025</p>
          <p style={{ color: '#515978', margin: '16px 0 0', fontSize: '14px', lineHeight: '20px' }}>
            You may contact us using the information below:
          </p>
          <p style={{ color: '#515978', margin: '16px 0 0', fontSize: '14px', lineHeight: '20px' }}>
            Merchant Legal entity name: Rx Pharma<br />
            Registered Address: Rk university, Rajkot<br />
            Rajkot, GUJARAT, 360020<br />
            Telephone No: 7762926694<br />
            E-Mail ID: ns193383@gmail.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
import React from 'react';

const AboutUsPage = () => {
  return (
    <div style={{ background: '#0a1d38', fontFamily: 'Lato, sans-serif', fontWeight: 400, padding: '24px' }}>
      <div style={{ background: '#ffffff', borderRadius: '32px 32px 0 0', padding: '32px 24px' }}>
        <div style={{ margin: '0 auto', maxWidth: '1000px' }}>
          <p style={{ fontSize: '24px', lineHeight: '30px', color: '#213554', margin: 0, fontWeight: 700 }}>About Us</p>
          <div style={{ width: '28px', height: '5px', backgroundColor: '#213554', marginTop: '16px' }}></div>
          <p style={{ color: '#515978', margin: '16px 0 0', fontSize: '14px', lineHeight: '20px' }}>
            Welcome to Rx Pharma, your trusted partner in pharmaceutical education and resources. Our mission is to empower healthcare professionals and enthusiasts with the knowledge they need to make informed decisions.
          </p>

          <p style={{ color: '#515978', margin: '16px 0 0', fontSize: '14px', lineHeight: '20px' }}>
            We are committed to providing accurate, up-to-date, and comprehensive information in the field of pharmaceuticals. Our platform offers a wide range of resources, including educational articles, industry news, and product insights.
          </p>

          <p style={{ color: '#515978', margin: '16px 0 0', fontSize: '14px', lineHeight: '20px' }}>
            At Rx Pharma, we value transparency, accessibility, and user satisfaction. Our dedicated team of experts works tirelessly to ensure that the content we provide meets the highest standards of quality and reliability.
          </p>

          <p style={{ color: '#515978', margin: '16px 0 0', fontSize: '14px', lineHeight: '20px' }}>
            Thank you for choosing Rx Pharma as your go-to source for pharmaceutical knowledge. We are here to support you every step of the way on your journey to better healthcare outcomes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
